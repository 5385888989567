<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-client-vue
          v-if="
            (getActiveUserData.userRoles == 'admin') ||
            (getActiveUserData.userRoles == 'subadmin') ||
            (getActiveUserData.userRoles == 'salesteamlead') ||
            (getActiveUserData.userRoles == 'salesperson')
          "
        ></adminvue-client-vue>
        <!-- <sales-team-lead-client-vue
          v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
        ></sales-team-lead-client-vue>
        <sales-person-client-vue
          v-else-if="getActiveUserData.userRoles == 'salesperson'"
        ></sales-person-client-vue> -->
        <no-component-found v-else></no-component-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noComponentFound from "../../components/noComponentFound";
import adminvue from "./components/project_admin";
// import salesteamleadtablevue from "./components/client_salesteamlead";
// import salespersonevue from "./components/client_salesperson";

export default {
  components: {
    "no-component-found": noComponentFound,
    "adminvue-client-vue": adminvue,
    // "sales-team-lead-client-vue": salesteamleadtablevue,
    // "sales-person-client-vue": salespersonevue
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
