var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard"},[_c('div',{staticClass:"container"},[(
        typeof _vm.getActiveUserData.user !== 'undefined' &&
          Object.keys(_vm.getActiveUserData.user).length
      )?_c('div',[(
          (_vm.getActiveUserData.userRoles == 'admin') ||
          (_vm.getActiveUserData.userRoles == 'subadmin') ||
          (_vm.getActiveUserData.userRoles == 'salesteamlead') ||
          (_vm.getActiveUserData.userRoles == 'salesperson')
        )?_c('adminvue-client-vue'):_c('no-component-found')],1):_c('div',{staticClass:"white-row loader-route"},[_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])}]

export { render, staticRenderFns }